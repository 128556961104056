const calculatorStep = {
  currentStepIndex: 3,
  total: 6,
  isAdditionalStep: false,
  additionalStepIndex: "",
  wasSecondStep: false
};

(function ($) {
  const $modal = $(".modal-calc"),
    $modalCalcClose = $modal.find(`.modal-calc-close`),
    $modalCalcStep = $modal.find(".modal-calc-step"),
    $modalStepClose = $modal.find(".modal-calc-step__close"),
    $modalCalcConfirmation = $modalStepClose.find('.close-btn .m-btn[data-action]'),
    $modalNextBtn = $('.calculator-btn[data-step]'),
    $modalBackLink = $modal.find('.modal-calc-back'),
    $modalCounter = $modal.find('.modal-calc-step__counter'),
    $successStep = $modal.find('.modal-calc-step[data-step="success"]'),
    $errorStep = $modal.find('.modal-calc-step[data-step="error"]'),
    $modalLanguageMultiple = $("#select-language-multiple"),
    $lang = $("html").attr("lang");

  // get Step Element By Id, setting modal counter step text
  const getStepElementById = (id) => $(`.modal-calc .modal-calc-step[data-step="${id}"]`);

  const setModalStepText = (isAdditionalFirstStep) => {
    let currentStep = Math.max(calculatorStep.currentStepIndex - 1, 2),
      totalSteps = calculatorStep.total;

    // If is additional step increase total step counter with + 1
    if (calculatorStep.isAdditionalStep) {
      totalSteps += 1;

      // If was second step and step is 2
      if (!calculatorStep.wasSecondStep && currentStep === 2) {
        calculatorStep.wasSecondStep = true;
      }

      currentStep += 1;
    }

    if (isAdditionalFirstStep || isNaN(currentStep)) currentStep = 2;

    const stepTrn = $modalCounter.attr('data-step-text');
    const ofTrn = $modalCounter.attr('data-step-of-text');

    $modalCounter.text(`${stepTrn} ${currentStep} ${ofTrn} ${totalSteps}`);

    // If step is last of current steps block (цепочка шагов)
    if (currentStep >= totalSteps) {
      $modalCounter.hide();
    } else {
      $modalCounter.show();
    }
  }

  const getCheckedValue = (name) => $(`.c-radio input[name="${name}"]:checked`).val();

  const showStep = (id) => {
    $modal.find('.modal-calc-step').removeClass("modal-calc-step-visible");
    getStepElementById(id).addClass("modal-calc-step-visible");
  }

  // Step 4 validation
  const stepValidationFour = () => {
    const requiredItem = $('.milengo-form-inner_required[data-required="step3"]');

    if (!$modalLanguageMultiple.val().length) {
      requiredItem.addClass("need-validation");

      return false;
    } else {
      requiredItem.removeClass("need-validation");

      return true;
    }
  }

  // Submit step validation
  const validateSubmitForm = () => {
    const $user = {
        first_name: $("#step-first_name"),
        last_name: $("#step-last_name"),
        job_title: $("#job-title"),
        company: $("#step-company"),
        email: $("#step-email"),
        phone: $("#step-phone"),
      },
      userData = {
        first_name: $user.first_name.val(),
        last_name: $user.last_name.val(),
        job_title: $user.job_title.val(),
        company: $user.company.val(),
        email: $user.email.val(),
        phone: $user.phone.val(),
      };

    // data validation logic
    let hasError = false;
    if (!userData.first_name.length) {
      $user.first_name.parent().addClass("need-validation");
      hasError = true;
    } else {
      $user.first_name.parent().removeClass("need-validation");
    }

    if (!userData.last_name.length) {
      $user.last_name.parent().addClass("need-validation");
      hasError = true;
    } else {
      $user.last_name.parent().removeClass("need-validation");
    }

    if (!userData.job_title.length) {
      $user.job_title.parent().addClass("need-validation");
      hasError = true;
    } else {
      $user.job_title.parent().removeClass("need-validation");
    }

    if (!userData.company.length) {
      $user.company.parent().addClass("need-validation");
      hasError = true;
    } else {
      $user.company.parent().removeClass("need-validation");
    }

    const $emailError = $user.email.parent().find('.milengo-form-error');
    let fieldErrorMessage = $emailError.attr('data-error-required-text');

    if (!userData.email.length) {
      $user.email.parent().addClass("need-validation");
      hasError = true;
      $emailError.text(fieldErrorMessage);
    } else {
      $user.email.parent().removeClass("need-validation");

      if (!checkEmail(userData.email)) {
        fieldErrorMessage = $emailError.attr('data-error-validation-text')
        $user.email.parent().addClass("need-validation");
        hasError = true;
        $emailError.text(fieldErrorMessage);
      }
    }

    if (userData.phone.length < 10) {
      $user.phone.parent().parent().addClass("need-validation");
      hasError = true;
    } else {
      $user.phone.parent().parent().removeClass("need-validation");
    }

    return hasError;
  }

  // Navigate to specific step
  const navigateToStep = (stepIndex) => {
    calculatorStep.currentStepIndex = stepIndex;
    $modal.find(".modal-calc-step").removeClass("modal-calc-step-visible");
    $modal.find(`.modal-calc-step[data-step="${stepIndex}"]`).addClass("modal-calc-step-visible");
  }

  // Calculator form submit to HS
  function calculatorFormSubmit(preparedData) {

    if (!preparedData) return false;

    const calculatorFormId = $modalCounter.attr("data-calculator-form-id");

    $.ajax({
      url: "https://api.hsforms.com/submissions/v3/integration/submit/5736365/" + calculatorFormId,
      method: "POST",
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(preparedData),
      success: () => {
        // After form submit show successStep
        showSuccessMessage();

        // And reset all calculator fields
        resetCalculatorFields();

        // Push google pricing_form_submit event to Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'pricing_form_submit'
        });
      },
      error: () => {
        // After form error submit show errorStep
        showErrorMessage();
      }
    });
  }

  // Modal next step button event
  $modalNextBtn.click(function () {
    const $this = $(this);
    let currentStep = $this.attr("data-step");

    // If is first step (by default need to open modal on this click)
    // This first step is from index page (not from modal)
    if (currentStep === "1") {
      // This radio input is from index page (not from modal)
      let firstStepValue = $('input[name="step0-radio"]:checked').val();

      // If firstStepValue is 3 or 5 show additional first step
      if (firstStepValue === "3") {
        showStep("0-1");
        calculatorStep.isAdditionalStep = true;
        calculatorStep.additionalStepIndex = "0-1";
        calculatorStep.currentStepIndex = "0-1";
        $modal.find('.modal-calc-back[data-step="3"]').removeClass("d-none");
      } else if (firstStepValue === "5") {
        showStep("0-2");
        calculatorStep.isAdditionalStep = true;
        calculatorStep.additionalStepIndex = "0-2";
        calculatorStep.currentStepIndex = "0-2";
        $modal.find('.modal-calc-back[data-step="3"]').removeClass("d-none");
      } else {
        showStep("3");
        calculatorStep.isAdditionalStep = false;
        $modal.find('.modal-calc-back[data-step="3"]').addClass("d-none");
      }

      setModalStepText();
      $modal.fadeIn(150);

      return false;
    }

    if (currentStep === "3") {
      hideTargetLanguage.sourceLanguageInputChange();
    }

    // Step 4 validation (dropdown)
    if (currentStep === "4") {
      const isValid = stepValidationFour();
      if (!isValid) return false;
    }

    // No more next steps, submit form
    if (currentStep === "submit") {
      const validationForm = validateSubmitForm();
      if (validationForm) return false;

      // Data manipulation
      const otherSourceLanguageValue = $("#step1-1-other").val(),
        otherSourceLanguage = otherSourceLanguageValue.length ? otherSourceLanguageValue : "",
        sourceLanguage = getCheckedValue('step1-1-radio') === "other" ? "Other" : getCheckedValue('step1-1-radio'),
        checkedStep0Id = $('input[name="step0-radio"]:checked').attr("id");

      let budget, otherBudget = "";

      // Budget estimation
      if ($("#budgetEstimation").is(":checked")) {
        budget = "";
        otherBudget = "true";
      } else {
        budget = $('.slider[data-sliderid="1"] .slider-row__value').text().replace('$', '').replace('€', '');
      }

      const userLanguage = $lang === 'en-US' ? 'English' : 'German';

      // Form data
      const data = {
        marketing_content: calculatorStep.additionalStepIndex === "0-1" ? getCheckedValue('step0-1-radio') : "",
        e_learning_form: calculatorStep.additionalStepIndex === "0-2" ? getCheckedValue('step0-2-radio') : "",
        translateContent: getCheckedValue('step0-radio') === "2" ? 'Technical' : $(`label[for="${checkedStep0Id}"]`).text(),
        translateContentAdditional: getAdditionalTranslateContent(),
        translateContentAdditionalDE: getAdditionalTranslateContentDE(),
        sourceLanguage,
        otherSourceLanguage,
        target_language: $modalLanguageMultiple.val().join(";"),
        budget,
        otherBudget,
        preferredCurrency: getCheckedValue('step1-5-radio'),
        userLanguage,
        user: {
          first_name: $("#step-first_name").val(),
          last_name: $("#step-last_name").val(),
          job_title: $("#job-title").val(),
          company: $("#step-company").val(),
          email: $("#step-email").val(),
          phone: $("#step-phone").val(),
        }
      };

      const preparedData = getPreparedData(data);

      // Submitting form
      calculatorFormSubmit(preparedData);

      // Exit steps after post data
      return false;
    }

    // Executed if is not first step, valid fourth and submit step
    currentStep = parseInt(currentStep);
    let nextStep = currentStep + 1;

    if (calculatorStep.currentStepIndex === "0-1" || calculatorStep.currentStepIndex === "0-2") {
      nextStep = 3;
    }

    navigateToStep(nextStep);
    setModalStepText();
  });

  // Modal back step button event
  $modalBackLink.click(function () {
    const $this = $(this);
    let currentStep = $this.attr("data-step");

    currentStep = parseInt(currentStep);
    let previousStep = currentStep - 1;

    if (calculatorStep.isAdditionalStep && currentStep === 3) {
      navigateToStep(calculatorStep.additionalStepIndex);
    } else {
      navigateToStep(previousStep);
    }
    setModalStepText();
  });

  // Modal close event
  $modalCalcClose.click(() => {
    let totalSteps = calculatorStep.total,
      currentStepIndex = calculatorStep.currentStepIndex;

    if (calculatorStep.isAdditionalStep) {
      totalSteps += 1;
    } else {
      currentStepIndex -= 1;
    }

    let stepsLeft = totalSteps - currentStepIndex + 1;

    // Is first step ("0-1" or "0-2")
    if (isNaN(stepsLeft)) stepsLeft = 6;

    // Display always 1 step left if it's smaller
    if (stepsLeft < 1) stepsLeft = 1;

    let stepsOrStepText = stepsLeft === 1 ? $modalCounter.attr("data-step-text") : $modalCounter.attr("data-step-text-plural"),
      leftWord = $modalCounter.attr("data-step-left-text");

    $modalStepClose.find(".modal-calc-step__close-text b").text(stepsLeft + ` ${stepsOrStepText}${leftWord ? ' ' + leftWord : ''}`);
    $modalCounter.hide();

    // If confirmation step is shown
    const isSuccessStepOrErrorStepOrCloseStep =
      $successStep.is(".modal-calc-step-visible") ||
      $modalStepClose.is(":visible") ||
      $errorStep.is(".modal-calc-step-visible");

    if (isSuccessStepOrErrorStepOrCloseStep) {
      $modal.fadeOut(150, () => {
        $successStep.removeClass("modal-calc-step-visible");
        $errorStep.removeClass("modal-calc-step-visible");
        $modalStepClose.removeClass("modal-calc-step__close-visible");

        // Return to default
        calculatorStep.currentStepIndex = 3;
        calculatorStep.isAdditionalStep = false;
        calculatorStep.wasSecondStep = false;
        calculatorStep.additionalStepIndex = '';
      });
    } else {
      $modal.find(".modal-calc-step").removeClass("modal-calc-step-visible");
      $modalStepClose.addClass("modal-calc-step__close-visible");
    }
  });

  // Modal close confirmation event
  $modalCalcConfirmation.click(function () {
    let action = $(this).attr("data-action");
    if (action === 'leave') {
      $modal.fadeOut(150, () => {
        $(".modal-calc-step").removeClass("modal-calc-step-visible");
        $modalStepClose.removeClass("modal-calc-step__close-visible");
        $modalCounter.show();

        // Return to default
        calculatorStep.currentStepIndex = 3;
        calculatorStep.isAdditionalStep = false;
        calculatorStep.wasSecondStep = false;
        calculatorStep.additionalStepIndex = '';
      });
    } else {
      let currentStep = calculatorStep.currentStepIndex;
      navigateToStep(currentStep);

      // Hide counter value if is last step
      if ((currentStep - 1) >= calculatorStep.total) {
        $modalCounter.hide();
      } else {
        $modalCounter.show();
      }

      $modalStepClose.removeClass("modal-calc-step__close-visible");
    }
  });

  // Slider Range change currency on slider based on previous step
  let sliderRangeCurrency = '$';
  const $sliderMin = $('.slider[data-sliderid="1"] .slider-row__min'),
    $sliderMax = $('.slider[data-sliderid="1"] .slider-row__max'),
    $sliderVal = $('.slider[data-sliderid="1"] .slider-row__value');

  $('input[name="step1-5-radio"]').on("change", function () {
    if (this.value === "EUR") {
      sliderRangeCurrency = "€";
      $sliderMin.text($sliderMin.text().replace("$", "€"));
      $sliderMax.text($sliderMax.text().replace("$", "€"));
      $sliderVal.text($sliderVal.text().replace("$", "€"));
    } else {
      sliderRangeCurrency = "$";
      $sliderMin.text($sliderMin.text().replace("€", "$"));
      $sliderMax.text($sliderMax.text().replace("€", "$"));
      $sliderVal.text($sliderVal.text().replace("€", "$"));
    }
  });

  // ionRangeSlider (input range)
  // Docs: http://ionden.com/a/plugins/ion.rangeSlider/api.html
  const $sliderRange = $(`.slider[data-sliderId="1"] .slider__range`);
  $sliderRange.ionRangeSlider({
    min: 0,
    max: 100000,
    from: 60000,
    skin: "round",
    type: "single",
    hide_min_max: true,
    hide_from_to: true,
    onChange: function (data) {
      $(`.slider[data-sliderId="1"] .slider-row__value`).text(sliderRangeCurrency + data.from);
    },
  });

  // Bootstrap select picker (for multiple select choose)
  // Docs: https://developer.snapappointments.com/bootstrap-select/
  const $modalSelectPicker = $('.modal-calc .selectpicker');
  const selectLanguagesText = $modalSelectPicker.attr('data-text');
  $modalSelectPicker.selectpicker({noneSelectedText: selectLanguagesText});

  // Input tel plugin customization
  // Docs: https://github.com/jackocnr/intl-tel-input
  const $phone = $("#step-phone");
  $phone.intlTelInput({
    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/utils.min.js",
    autoPlaceholder: "aggressive",
    preferredCountries: ['de', 'us', 'gb', 'it', 'fr'],
    customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
      const phoneNumber = $phone.val();

      if (!phoneNumber.includes(selectedCountryData.dialCode)) {
        const selectedDialCode = '+' + selectedCountryData.dialCode;

        $phone.val(selectedDialCode);
        return selectedDialCode;
      }
    }
  });

  // Allow only digits to input phone
  $phone.on("input", function () {
    if (!/^(?:[+\d].*\d|\d)$/.test(this.value)) {
      this.value = this.value.replace(/[^\d.+]/g, '');
    }
  });

  // First step logic (enable/disable input on radio change)
  const $firstStepRadio = $modalCalcStep.find(`input[name="step1-1-radio"]`),
    $firstStepInput = $modalCalcStep.find("#step1-1-other");

  const firstStepOtherLogic = () => {
    $modalCalcStep.find(`input[name="step1-1-radio"]`).prop("checked", false);
    $modalCalcStep.find(`input#step1-1-radio3`).prop("checked", true);

    const otherInputValue = $firstStepInput.val();

    if (!otherInputValue.length) {
      $firstStepInput.removeAttr("disabled").addClass("milengo-form-control_invalid").focus();
      $modalNextBtn.attr("disabled", "disabled");
    }

    $firstStepInput.on("input", function () {
      const $this = $(this),
        value = $this.val();

      if (value.length > 0) {
        $this.removeClass("milengo-form-control_invalid");
        $modalNextBtn.removeAttr("disabled");
      } else {
        $this.addClass("milengo-form-control_invalid");
        $modalNextBtn.attr("disabled", "disabled");
      }
    });
  }

  $firstStepRadio.on("change", function () {
    let val = $(this).val();
    if (val === "other") {
      firstStepOtherLogic();
    } else {
      $firstStepInput.attr("disabled", "disabled").val("").removeClass("milengo-form-control_invalid");
      $modalNextBtn.removeAttr("disabled");
    }
  });

  $(document).on('click', function (e) {
    if (e.target.id === 'step1-1-other') {
      firstStepOtherLogic();
    }
  });

  let $sliderRangeData = $sliderRange.data("ionRangeSlider"),
    $budgetEstimation = $("#budgetEstimation");

  $budgetEstimation.on("change", function () {
    if ($(this).is(":checked")) {
      $sliderRangeData.update({disable: true});
    } else {
      $sliderRangeData.update({disable: false});
    }
  });

  const getPreparedData = (data) => {
    let preparedData = [
      {
        name: 'leadsource',
        value: 'Website - Pricing Calculator'
      },
      {
        name: "form___translation_profile",
        value: data.translateContent
      },
      {
        name: "form___translation_profile_additional",
        value: data.translateContentAdditional
      },
      {
        name: "form___translation_profile_additional_de",
        value: data.translateContentAdditionalDE
      },
      {
        name: "form___marketing_content",
        value: data.marketing_content
      },
      {
        name: "form___e_learning_content",
        value: data.e_learning_form
      },
      {
        name: "form___target_language",
        value: data.target_language
      },
      {
        name: "form___source_language",
        value: data.sourceLanguage
      },
      {
        name: "form___other_source_language",
        value: data.otherSourceLanguage
      },
      {
        name: "form___currency",
        value: data.preferredCurrency
      },
      {
        name: "form___expected_annual_budget",
        value: data.budget
      },
      {
        name: "form___other_expected_anual_budget",
        value: data.otherBudget
      },
      {
        name: "firstname",
        value: data.user.first_name
      },
      {
        name: "lastname",
        value: data.user.last_name
      },
      {
        name: "email",
        value: data.user.email
      },
      {
        name: "company",
        value: data.user.company
      },
      {
        name: "jobtitle",
        value: data.user.job_title
      },
      {
        name: "phone",
        value: data.user.phone
      },
      {
        name: "form___submited_language",
        value: data.userLanguage
      }
    ];

    // Remove additional field if language is not needed
    preparedData = removeFormFieldByName(preparedData, $lang === 'en-US' ? 'form___translation_profile_additional_de' : 'form___translation_profile_additional');

    const context = {
      pageUri: document.location.href,
      pageName: document.title
    };

    const hutk = getCookie('hubspotutk');

    if (hutk) {
      context.hutk = hutk;
    }

    return {
      fields: preparedData,
      context
    };
  };

  const removeFormFieldByName = (fields, fieldName) => fields.filter(field => field.name !== fieldName);

  const getAdditionalTranslateContent = () => {
    let value;

    switch (getCheckedValue('step0-radio')) {
      case '1':
        value = 'Software Localization';
        break;
      case '2':
        value = 'Technical Documentation Translation';
        break;
      case '3':
        value = 'Marketing Translation';
        break;
      case '4':
        value = 'Multimedia';
        break;
      case '5':
        value = 'E-Learning Localization';
        break;
    }

    return value;
  }

  const getAdditionalTranslateContentDE = () => {
    let value;

    switch (getCheckedValue('step0-radio')) {
      case '1':
        value = 'Softwarelokalisierung';
        break;
      case '2':
        value = 'Übersetzung von technischer Dokumentation';
        break;
      case '3':
        value = 'Marketingübersetzung';
        break;
      case '4':
        value = 'Multimedia';
        break;
      case '5':
        value = 'Lokalisierung von E-Learning-Inhalten';
        break;
    }

    return value;
  }

  const resetCalculatorFields = () => {
    const inputRadioElements = [
      "step1-1-radio",
      "step1-5-radio",
      "step0-1-radio",
      "step0-2-radio"
    ];

    // Reset input radio from page
    $(`.calculator input[name="step0-radio"]`).prop("checked", false);
    $(`.calculator input#step0-1`).prop("checked", true);
    $firstStepInput.attr("disabled", "disabled").val("");

    // Reset all input radios
    for (let field of inputRadioElements) {
      const fieldId = field + "1";
      $modalCalcStep.find(`input[name="${field}"]`).prop("checked", false);
      $modalCalcStep.find(`input#${fieldId}`).prop("checked", true);
    }

    // Reset dropdown picker
    $modalSelectPicker.selectpicker('deselectAll');

    // Reset budget
    const budgetSliderInstance = $sliderRange.data("ionRangeSlider");
    budgetSliderInstance.update({from: 60000});
    budgetSliderInstance.callOnChange();
    $modalCalcStep.find("#budgetEstimation").prop("checked", false);
    $sliderRangeData.update({disable: false});

    const inputTextElements = [
      "#step1-1-other",
      "#step-first_name",
      "#step-last_name",
      "#job-title",
      "#step-company",
      "#step-email",
      "#step-phone"
    ];

    for (let field of inputTextElements) {
      const $field = $modalCalcStep.find(field);
      field === "#step-phone" ? $field.val("+49") : $field.val("");
    }
  }

  const showSuccessMessage = () => {
    $modalCounter.hide();
    $modalCalcStep.removeClass("modal-calc-step-visible");
    $modalStepClose.removeClass("modal-calc-step-visible");
    $successStep.addClass("modal-calc-step-visible");
  }

  const showErrorMessage = () => {
    $modalCounter.hide();
    $modalCalcStep.removeClass("modal-calc-step-visible");
    $modalStepClose.removeClass("modal-calc-step-visible");
    $errorStep.addClass("modal-calc-step-visible");
  }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const hideTargetLanguage = {
    allLanguages: [],

    getSelectLanguageOptions() {
      let selectedLanguages = [];

      $modalLanguageMultiple.find("option").each(function () {
        selectedLanguages.push([this.value, this.innerText])
      });

      return selectedLanguages;
    },

    reloadSelectPickerLanguage() {
      $modalSelectPicker.selectpicker('destroy');
      $modalSelectPicker.selectpicker({noneSelectedText: selectLanguagesText});
    },

    addOptionToModalLanguageMultiple(language) {
      let languageValue = language[0];
      language = language[1];
      $modalLanguageMultiple.append(`<option value="${languageValue}">${language}</option>`);
    },

    resetSelectLanguageOptions() {
      $modalLanguageMultiple.empty();
      this.allLanguages.forEach(lang => this.addOptionToModalLanguageMultiple(lang));
      this.reloadSelectPickerLanguage();
    },

    removeLanguageFromOptions(language, languagesList) {
      $modalLanguageMultiple.empty();

      // Get all languages exclude "language"
      let languages = languagesList.filter(item => item[0] !== language);

      languages.forEach(lang => this.addOptionToModalLanguageMultiple(lang));
      this.reloadSelectPickerLanguage();

      return languages;
    },

    removeLanguageFromOptionsLoop(removeLanguages) {
      let languages = this.allLanguages;

      for (let lang of removeLanguages) {
        languages = this.removeLanguageFromOptions(lang, languages);
      }

      return languages;
    },

    sourceLanguageInputChange() {
      this.resetSelectLanguageOptions();

      const firstStepValue = $(`.modal-calc .c-radio input[name="step1-1-radio"]:checked`).val(),
        contentType = $(`.calculator input[name="step0-radio"]:checked`).val(),
        isSoftware = contentType === '1',
        isTechnicalDocumentation = contentType === '2',
        isMarketing = contentType === '3',
        isMultimedia = contentType === '4',
        isLearning = contentType === '5',

        isEnglishLanguage = firstStepValue === 'English' || firstStepValue === 'Englisch',
        isGermanLanguage = firstStepValue === 'German' || firstStepValue === 'Deutsch';

      let removeLanguages = [];

      const getSoftwareMarketingLearningEnglish = () =>
          ["English", "Englisch"],

        getSoftwareMarketingLearningGerman = () => [
          "German", "Deutsch",
          "Thai", "Thailändisch",
          "Indonesian", "Indonesisch",
          "Vietnamese", "Vietnamesisch"
        ],

        getTechnicalDocumentationEnglish = () => [
          "English", "Englisch",
          "Arabic", "Arabisch",
          "Bulgarian", "Bulgarisch",
          "Indonesian", "Indonesisch"
        ],

        getTechnicalDocumentationGerman = () => [
          "German", "Deutsch",
          "Arabic", "Arabisch",
          "Bulgarian", "Bulgarisch",
          "Hungarian", "Ungarisch",
          "Serbian", "Serbisch",
          "Slovak", "Slowakisch",
          "Slovenian", "Slowenisch",
          "Ukrainian", "Ukrainisch",
          "Traditional Chinese", "traditionelles Chinesisch",
          "Korean", "Koreanisch"
        ],

        getMultimediaEnglishLanguage = () => ["English", "Englisch"],
        getMultimediaGermanLanguage = () => ["German", "Deutsch"];

      // Software OR Marketing OR E-learning
      if (isSoftware || isMarketing || isLearning) {
        if (isEnglishLanguage) {
          removeLanguages = getSoftwareMarketingLearningEnglish();
        } else if (isGermanLanguage) {
          removeLanguages = getSoftwareMarketingLearningGerman();
        }
      } else if (isTechnicalDocumentation) {
        if (isEnglishLanguage) {
          removeLanguages = getTechnicalDocumentationEnglish();
        } else if (isGermanLanguage) {
          removeLanguages = getTechnicalDocumentationGerman();
        }
      } else if (isMultimedia) {
        if (isEnglishLanguage) {
          removeLanguages = getMultimediaEnglishLanguage();
        } else if (isGermanLanguage) {
          removeLanguages = getMultimediaGermanLanguage();
        }
      }

      this.removeLanguageFromOptionsLoop(removeLanguages);
    },

    init() {
      this.allLanguages = this.getSelectLanguageOptions();
    }
  }

  hideTargetLanguage.init();

})(jQuery);